import React from "react";
import R14 from "../core";
const constants = {
  ANNOTATION_TYPE_FIELD: "FIELD",
  ANNOTATION_TYPE_FIELD_GROUP: "FIELD_GROUP",
  ANNOTATION_TYPE_SELECTIONS: "SELECTIONS",
  ANNOTATION_TYPE_QUESTION: "QUESTION",
  SORT_BY_LAYOUT: "SORT_BY_LAYOUT",
  INDICATOR_STATE_ACTIVE: "ACTIVE",
  INDICATOR_STATE_SELECTED: "SELECTED",
};

export default class DocumentSetUiDomain extends R14.DomainInstances {
  constructor(key) {
    super();
    // for(let k in constants) this[]
  }
  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let docSet = await this.dm.manualEntry.fetchDocumentSet(uid);
    if (!docSet) return null;
    let documentSet = new DocumentSetUiInstanceDomain(
      this,
      uid,
      docSet,
      options
    );
    await documentSet.init();
    this.addInstance(uid, documentSet);
    return documentSet;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}
class DocumentSetUiInstanceDomain extends R14.DomainInstances {
  constructor(documentSets, uid, documentSet, options = {}) {
    super();
    this._documentSets = documentSets;
    this._documentSet = documentSet;
    this._pageAnnotationMap = {
      [constants.ANNOTATION_TYPE_FIELD]: {},
      [constants.ANNOTATION_TYPE_FIELD_GROUP]: {},
    };
    this._uid = uid;
    // this._pages = [];
    this._annotations = new DocumentSetAnnotationsUiDomain(this);
    this._currentAnnotation = null;
    this._saving = false;
    this._documentCompleteDialogOptions = {};
    this._currPage = null;
    this.state = {
      documentCompleteDialogVisible: false,
      currPage: null,
      currAnnotation: null,
      metadata: {
        state: this.dm.manualEntry.STATE_QUEUED,
      },
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
      totalFieldAnnotations: documentSet.totalFieldAnnotations,
      fieldAnnotationsPageInfo: documentSet.fieldAnnotationsPageInfo,
      annotationsLoading: false,
    };
  }
  get uid() {
    return this._uid;
  }
  get annotationSetUid() {
    return this._documentSet.annotationSetUid;
  }
  get editable() {
    return this.dm.manualEntry.documentSetEditable === true;
  }
  hasAppFeature(value) {
    return this.dm.manualEntry.hasAppFeature(value);
  }
  // get disableReject() {
  //   return true;
  //   return this.dm.manualEntry.disableReject === true;
  // }
  // get disableRejectRemaining() {
  //   return true;
  //   return (
  //     this.disableReject || this.dm.manualEntry.disableRejectRemaining === true
  //   );
  // }
  // get disableAcceptRemaining() {
  //   return true;
  //   return this.dm.manualEntry.disableAcceptRemaining === true;
  // }
  // get disableDocumentPreview() {
  //   return false;
  //   return this.dm.manualEntry.disableDocumentPreview === true;
  // }
  get initialDocumentScaleMode() {
    return this.dm.manualEntry._initialDocumentScaleMode;
  }
  get documentImageStorageMode() {
    return this._documentSet.documentImageStorageMode;
  }
  get totalPages() {
    return this.state.totalDocumentImages;
    //return this._documentSet.totalDocumentImages;
  }
  get totalFieldAnnotations() {
    return this.state.totalFieldAnnotations;
  }
  get currentPage() {
    if (!this._currPage || this._currPage.number !== this.state.currPage)
      this._currPage = this.getPage(this.state.currPage);
    return this._currPage;
  }
  get currentPageNumber() {
    return this.state.currPage;
  }
  get currentAnnotation() {
    // Check annotation
    return this.state.currAnnotation &&
      this._currentAnnotation &&
      this._currentAnnotation.uuid === this.state.currAnnotation.uuid
      ? this._currentAnnotation
      : null;
    // return this.state.currAnnotation
    //   ? this.getAnnotation(this.state.currAnnotation.uuid)
    //   : null;
  }
  get currentAnnotationInitialized() {
    return this._currAnnotationInitialized;
  }
  get currentAnnotationKey() {
    return this.state.currAnnotation ? this.state.currAnnotation.key : null;
  }
  get currentAnnotationType() {
    return this.state.currAnnotation ? this.state.currAnnotation.type : null;
  }
  get pageScroller() {
    return this.ui.scroller("pageScroller");
  }
  get annotationScroller() {
    return this.ui.scroller("annotationScroller");
  }
  get pages() {
    // Keep page iteration in order
    return this._documentSet.pages.map((page) => this.getPage(page.number));
  }
  get documentImagesPageInfo() {
    return this.state.documentImagesPageInfo;
  }
  get fieldAnnotationsPageInfo() {
    return this.state.fieldAnnotationsPageInfo;
  }
  get annotations() {
    return this._annotations;
  }
  findFieldAnnotations(options = {}) {
    return this.findAnnotations({
      type: constants.ANNOTATION_TYPE_FIELD,
      sortBy: constants.SORT_BY_LAYOUT,
      ...options,
    });
  }
  get documentCompleteDialogVisible() {
    return this.state.documentCompleteDialogVisible;
  }
  get documentCompleteDialogOptions() {
    return this._documentCompleteDialogOptions || {};
  }
  get shouldReview() {
    return this.metadata.state === this.dm.manualEntry.STATE_REVIEW;
  }
  get nextAnnotation() {
    return this.findNextAnnotation({
      currentAnnotation: this.currentAnnotation,
    });
  }
  findNextAnnotation(options = {}) {
    let ret = null;
    let annotations = this.findAnnotations({
      type: constants.ANNOTATION_TYPE_FIELD,
      sortBy: constants.SORT_BY_LAYOUT,
    });

    if (options.currentAnnotation) {
      let foundNext = false;
      for (let annotation of annotations) {
        if (annotation.uuid === this.currentAnnotation.uuid) {
          foundNext = true;
        } else if (
          foundNext &&
          annotation.metadata.state === this.dm.manualEntry.STATE_QUEUED
        ) {
          ret = annotation;
          break;
        }
      }
    } else
      ret = annotations.find(
        (annotation) =>
          annotation.metadata.state === this.dm.manualEntry.STATE_QUEUED
      );
    return ret;
  }
  get metadata() {
    return this.state.metadata;
  }
  findAnnotations(options = {}) {
    let annotations = this.annotations.filter((annotation) => {
      if (options.pageNumber && !annotation.inPage(options.pageNumber))
        return false;
      if (options.type && annotation.type !== options.type) return false;
      if (
        options.parentAnnotationUuid &&
        annotation.uuid !== options.parentAnnotationUuid
      )
        return false;
      return true;
    });
    if (options.sortBy) {
      annotations.sort((a, b) => {
        return a.positionIndex > b.positionIndex ? 1 : -1;
        // if (a.documentPositionIndex === b.documentPositionIndex)
        //   return a.positionIndex > b.positionIndex ? -1 : 1;
        // else return a.documentPositionIndex > b.documentPositionIndex ? 1 : -1;
      });
    }
    return annotations;
  }

  // runDocumentAnnotationsAction(action, updatedAnnotations) {
  //   throw new Error("REMOVED");
  //   let updatedPositionIndex = updatedAnnotations[0].positionIndex;
  //   let toPage = updatedAnnotations[0].documentPositionIndex;
  //   let fromPage = null;
  //   let updatedUuids = updatedAnnotations.map((annotation) => annotation.uuid);

  //   // Get all annotations that are not being updated
  //   let annotations = this.annotations
  //     .filter((annotation) => {
  //       if (updatedUuids.includes(annotation.uuid))
  //         fromPage = annotation.documentPositionIndex;
  //       return updatedUuids.includes(annotation.uuid) ? false : true;
  //     })
  //     .map((annotation) => ({
  //       uuid: annotation.uuid,
  //       documentUuid: annotation.documentUuid,
  //       positionIndex: annotation.positionIndex,
  //       documentPositionIndex: annotation.documentPositionIndex,
  //     }))
  //     .sort((a, b) => (a.positionIndex > b.positionIndex ? 1 : -1));
  //   annotations.splice(updatedPositionIndex - 1, 0, ...updatedAnnotations);

  //   // Update the position index, page, document position index
  //   let positionIndex = 1;
  //   annotations.forEach((annotation) => {
  //     //let updateAnnotation = this.annotations.get(annotation.uuid);
  //     //updateAnnotation.positionIndex = positionIndex++;
  //     let documentPositionIndex = annotation.documentPositionIndex;
  //     if (!updatedUuids.includes(annotation.uuid)) {
  //       //if(documentPositionIndex > updateDocumentPositionIndex)
  //       switch (action) {
  //         case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
  //           if (annotation.documentPositionIndex >= toPage)
  //             documentPositionIndex++;
  //           break;
  //         case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
  //           if (
  //             toPage > fromPage &&
  //             annotation.documentPositionIndex > fromPage &&
  //             annotation.documentPositionIndex <= toPage
  //           )
  //             documentPositionIndex--;
  //           else if (
  //             toPage < fromPage &&
  //             annotation.documentPositionIndex < fromPage &&
  //             annotation.documentPositionIndex >= toPage
  //           )
  //             documentPositionIndex++;
  //           break;
  //         default:
  //           throw new Error("Unknown action");
  //       }
  //       // Updated the annotation
  //     }
  //     let updateAnnotation = this.annotations.exists(annotation.uuid)
  //       ? this.annotations.getInstance(annotation.uuid)
  //       : this.addAnnotationInstance(annotation);

  //     // console.log("CHECK UPDATE: ", {
  //     //   uuid: annotation.uuid,
  //     //   updated: updatedUuids.includes(annotation.uuid),
  //     //   uPidx: updateAnnotation.positionIndex,
  //     //   udPidx: updateAnnotation.documentPositionIndex,
  //     //   upn: updateAnnotation.pageNumber,
  //     //   positionIndex,
  //     //   documentPositionIndex,
  //     // });

  //     updateAnnotation.positionIndex = positionIndex;
  //     updateAnnotation.documentPositionIndex = documentPositionIndex;
  //     updateAnnotation.pageNumber = documentPositionIndex;

  //     positionIndex++;
  //   });
  // }

  findAnnotation(options = {}) {
    let ret = null;
    let prevAnnotation = null;

    for (let annotation of this.annotations.toArray()) {
      if (options.type && annotation.type !== options.type) continue;
      if (
        options.beforeAnnotationUuid &&
        annotation.uuid === options.beforeAnnotationUuid
      ) {
        ret = prevAnnotation;
        break;
      } else if (
        options.afterAnnotationUuid &&
        prevAnnotation &&
        prevAnnotation.uuid === options.afterAnnotationUuid
      ) {
        ret = annotation;
        break;
      }
      prevAnnotation = annotation;
    }
    return ret;
  }
  async init() {
    if (!this._documentSet.pages) return false;
    // Create pages
    this._documentSet.pages.sort((a, b) => (a.number > b.number ? 1 : -1));
    for (let page of this._documentSet.pages) {
      this.addPageInstance(page);
    }
    this.addAnnotationInstances();
    await this.update();
  }
  async update() {
    let metadata = this.state.metadata;
    let nextAnnotation = this.findNextAnnotation({
      currentAnnotation: this.currentAnnotation,
    });
    if (!nextAnnotation) {
      let complete = false;
      // If all annotations are loaded, look for any incomplete
      if (this.totalFieldAnnotations === this.findFieldAnnotations().length) {
        nextAnnotation = this.findNextAnnotation();
        complete = nextAnnotation ? false : true;
      } else {
        await this.loadAnnotations({
          findNext: true,
          currentAnnotationUuid: this.currentAnnotation
            ? this.currentAnnotation.uuid
            : null,
          updateScroller: true,
        });
        nextAnnotation = this.findNextAnnotation();
        if (!nextAnnotation) complete = true;
      }
      if (complete) {
        metadata.state = this.dm.manualEntry.STATE_REVIEW;
        this._documentCompleteDialogOptions = {
          startTime: new Date(),
        };
        this.setState({
          documentCompleteDialogVisible: true,
          metadata,
        });
      }
    }
    return nextAnnotation;
  }

  async loadNextDocuments(options = {}) {
    let page = this.documentImagesPageInfo.page;
    if (!this.documentImagesPageInfo.hasNextPage)
      throw new Error("Next page out of range");
    return await this.loadDocuments({
      ...options,
      page: page + 1,
    });
  }
  async loadPrevDocuments(options = {}) {
    let page = this.documentImagesPageInfo.page;
    if (page <= 1) throw new Error("Previous page out of range");
    return await this.loadDocuments({
      ...options,
      page: page - 1,
    });
  }
  goToDocumentPage(documentPage, options = {}) {
    this.navToPage(documentPage);
  }
  documentLoaded(documentUuid) {
    let ret = false;

    for (let p of this.pages) {
      // console.log("check for doc", p.number, documentUuid, p.documentUuid);
      if (p.documentUuid === documentUuid) {
        ret = true;
        break;
      }
    }
    return ret;
  }
  async loadAnnotations(options = {}) {
    let documentSet =
      options.documentSet ||
      (await this.dm.manualEntry.loadAnnotationSetAnnotations(
        this.annotationSetUid,
        {
          ...options,
        }
      ));

    const annotations = await this.updateAnnotationInstances(
      documentSet.annotations,
      { reset: options.reset || false }
    );
    options.updateScroller &&
      this.annotationScroller &&
      (await this.annotationScroller.addPageData(
        documentSet.fieldAnnotationsPageInfo.page,
        annotations,
        { reset: options.reset || false }
      ));

    return annotations;
  }
  async loadDocuments(options = {}) {
    options.loading !== false && this.ui.progressIndicator.show();
    // options.loading !== false &&
    //   this.setState({
    //     documentImagesLoading: true,
    //   });

    let documentSet =
      options.documentSet ||
      (await this.dm.manualEntry.loadAnnotationSetDocuments(
        this.annotationSetUid,
        {
          ...options,
        }
      ));

    // for (let page of this._documentSet.pages) {
    //   await this.addPageInstance(page);
    // }
    const pages = await this.updatePageInstances(documentSet.pages, {
      reset: options.reset || false,
    });

    if (options.updateScroller && this.pageScroller) {
      let res = await this.pageScroller.addPageData(
        documentSet.documentImagesPageInfo.page,
        pages,
        {
          reset: options.reset || false,
        }
      );
    }

    // Update the current page
    let newCurrPage = documentSet.pages.find(
      (page) => page.documentUuid === this.currentPage.documentUuid
    );
    if (newCurrPage) {
      this.currentPage.update(newCurrPage);
    }

    options.loading !== false &&
      this.ui.progressIndicator.hide({ timeout: 500 });

    // this.addAnnotationInstances();
    this.setState({
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
    });
    return pages;
  }
  async rotateDocument() {
    if (!this.hasAppFeature("enableDocumentRotate")) return false;
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_ROTATE,
      this.currentPage.state.rotate,
      {
        progressIndicator: true,
        documentImagesLoading: true,
      }
    );
  }
  async moveDocument(toPage) {
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE,
      toPage,
      {
        documentImagesLoading: true,
        annotationsLoading: true,
      }
    );
  }
  async copyDocument(toPage) {
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY,
      toPage,
      {
        documentImagesLoading: true,
        annotationsLoading: true,
      }
    );
  }
  async runDocumentAction(action, value, options = {}) {
    options.progressIndicator && this.ui.progressIndicator.show();
    this.setState({
      documentImagesLoading: options.documentImagesLoading || false,
      annotationsLoading: options.annotationsLoading || false,
    });

    // Check if current annotation is being moved
    // switch (action) {
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
    //     if (
    //       this.currentPage.documentUuid === currentAnnotationUuid.documentUuid
    //     ) {

    //     }
    //     break;
    //   default: // Do nothing
    // }

    let documentSet = await this.dm.manualEntry.runAnnotationSetDocumentAction(
      this.annotationSetUid,
      this.currentPage.documentUuid,
      action,
      parseInt(value),
      this.currentAnnotation.uuid
    );

    switch (action) {
      case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
      case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
        break;
      default:
      // Do nothing
    }

    await this.loadDocuments({
      documentSet,
      updateScroller: true,
      reset: true,
    });
    let updatedPage = this.pages.find((page) => {
      return (
        page.documentUuid === this.currentPage.documentUuid &&
        page.number !== this.currentPage.number
      );
    });
    updatedPage && this.navToPage(updatedPage.number);

    const lastAnnotationPositionIndex = this.currentAnnotation.positionIndex;

    await this.loadAnnotations({
      documentSet,
      updateScroller: true,
      reset: true,
    });

    // Check if the current annotation needs to be scrolled to
    if (
      this.annotationScroller &&
      lastAnnotationPositionIndex &&
      this.currentAnnotation.positionIndex &&
      lastAnnotationPositionIndex !== this.currentAnnotation.positionIndex
    )
      this.annotationScroller.scrollToIndex(
        this.currentAnnotation.positionIndex - 1,
        {
          autoAnimate: true,
        }
      );

    // let updatedAnnotation = this.annotations.find(
    //   (annotation) => annotation.uuid === this.currentAnnotation.uuid
    // );
    // updatedAnnotation && this.setCurrentAnnotation(updatedAnnotation);

    this.setState({
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
      annotationsLoading: false,
    });

    // Remove current pages
    // let forwardPage = null;
    // let forwardAnnotationPage = null;
    // switch (action) {
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
    //     for (let page of documentSet.pages) {
    //       if (page.documentUuid === this.currentPage.documentUuid)
    //         forwardPage = page.number;
    //     }
    //     break;
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
    //     forwardPage = value;
    //     break;
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_ROTATE:
    //     //forwardPage = this.currentPage.number;
    //     break;
    // }

    //this.loadDocuments({ documentSet, loading: false, updateScroller: true });

    // await this.annotationScroller.reload();
    // await this.pageScroller.reload();
    options.progressIndicator &&
      this.ui.progressIndicator.hide({ timeout: 750 });

    // console.log("CURR ANNOATION?", documentSet, this.currentAnnotation);

    // if (documentSet.annotations && documentSet.annotations.length)
    //   this.runDocumentAnnotationsAction(action, documentSet.annotations);

    // await this.updatePageInstances(documentSet.pages, {
    //   pageNumber: forwardPage,
    // });
    // for (let page of this._documentSet.pages) {
    //   console.log("AddPageInstance!!!", page);
    //   await this.addPageInstance(page, { remove: true });
    // }
    // this.cleanupPageInstances();
    // await this.addAnnotationInstances();

    return true;
  }

  remove() {
    this._documentSets.removeInstance(this.uid);
  }
  async complete(options = {}) {
    if (this._saving) {
      return false;
    }
    if (
      options.logout &&
      !window.confirm("Are you sure you want to complete and logout?")
    )
      return false;
    this._saving = true;
    this.ui.progressIndicator.show();
    let complete = await this.dm.manualEntry.saveQueue({
      force: true,
      currentAnnotation: {
        uuid: this.currentAnnotation.uuid,
        state: this.currentAnnotation.metadata.state,
        reject: this.currentAnnotation.metadata.reject,
      },
      ...this.documentCompleteDialogOptions,
    });
    this.remove();
    this.ui.progressIndicator.hide({ timeout: 750 });
    this.ui.snackBar.show({
      message: "Your updates have been submitted.",
      variant: "success",
    });
    this.nav.to(options.logout ? "logout" : "manualEntryLoad");
    this._saving = false;
  }
  // async complete() {
  //   //try {
  //   let complete = await this.dm.manualEntry.saveQueue();
  //   this.remove();
  //   console.log("CHECK COMPLETE", complete);
  // }
  timeout() {
    this.remove();
    this.dm.manualEntry.timeout();
  }
  setDocumentCompleteDialogVisible(
    documentCompleteDialogVisible,
    documentCompleteDialogOptions = {}
  ) {
    this._documentCompleteDialogOptions = documentCompleteDialogOptions;
    this.setState({
      documentCompleteDialogVisible,
    });
  }
  setCurrentPage(number) {
    number = parseInt(number);
    if (this.state.currPage === number) return true;
    let currPage = this.getPage(number);

    if (!currPage) return false;

    // Make sure currPage is available
    let activeItemStyle =
      this.currentAnnotation &&
      this.currentAnnotation.documentUuid === currPage.documentUuid
        ? constants.INDICATOR_STATE_SELECTED
        : constants.INDICATOR_STATE_ACTIVE;

    this.pageScroller &&
      this.pageScroller.setActiveItemName(currPage.key, activeItemStyle);

    this.pageScroller &&
      this.pageScroller.scrollToIndex(number - 1, {
        autoScroll: true,
        autoAnimate: true,
      });

    this._currPage = currPage;
    this.setState({
      currPage: currPage.number,
    });

    return true;
  }
  refreshCurrentAnnotation() {
    // What is the point of this?
    return false;
    let currAnnotation = this.state.currAnnotation;
    this.annotationScroller.setActiveItemName(null);
    this.setState({ currAnnotation: null });
    this._currentAnnotation = null;
    if (!currAnnotation) return false;
    let annotation = this.annotations.getInstance(currAnnotation.uuid) || null;
    if (!annotation) return false;
    this.setCurrentAnnotation(annotation);
  }
  setCurrentAnnotation(annotation) {
    if (
      this.state.currAnnotation &&
      this.state.currAnnotation.uuid === annotation.uuid
    )
      return true;

    let currPage = this.getPage(annotation.pageNumber);
    this.annotationScroller.setActiveItemName(annotation.uuid);
    this.forEach((page) => page.setCurrentAnnotation(annotation));
    this._currentAnnotation = annotation;
    this.setState({
      currAnnotation: {
        uuid: annotation.uuid,
        documentUuid: annotation.documentUuid,
      },
    });

    this.annotationScroller &&
      this.annotationScroller.scrollToIndex(annotation.positionIndex - 1, {
        autoAnimate: true,
      });

    currPage &&
      this.pageScroller &&
      this.pageScroller.setActiveItemName(
        currPage.key,
        constants.INDICATOR_STATE_SELECTED
      );

    return true;
  }
  setMetadata(metadata) {
    this.setState({ metadata });
  }
  getPage(number, options = {}) {
    let pageKey = this.createPageKey(number);
    let page = this.getInstance(pageKey) || null;
    page && options.current && this.setCurrentPage(number);
    return page;
    // let page = this.pages[number - 1];
    // if (!page || page.number !== number)
    //   page = this.findPage({ number: number });
    // return page;
  }
  getAnnotation(uuid, options = {}) {
    // console.log("get annotation")
    // if(this.currentAnnotation.uuid === uuid) return this.currentAnnotation;
    let annotation = this.annotations.getInstance(uuid) || null;
    annotation && options.current && this.setCurrentAnnotation(annotation);
    return annotation;
  }
  navToPage(number, options = {}) {
    // R14.instance.app.ui.progressIndicator.show();
    let navMethod = options.replace ? "replace" : "to";
    let params = {
      uid: this.uid,
      pageNumber: parseInt(number),
    };

    this.nav[navMethod](`manualEntryDocumentSetDocument`, params);

    // this.nav[navMethod](
    //   options.refresh
    //     ? "manualEntryDocumentSetDocumentReload"
    //     : `manualEntryDocumentSetDocument`,
    //   params
    // );
    // R14.instance.app.ui.progressIndicator.hide({ timeout: 500 });
  }
  navToAnnotation(annotationUuid) {
    this.nav.to(`manualEntryDocumentSetAnnotationEdit`, {
      uid: this.uid,
      annotationUuid,
    });
  }
  navToNextAnnotation(options = {}) {
    let annotation = null;
    if (options.afterAnnotationUuid) {
      throw new Error("HERE");
    } else annotation = this.nextAnnotation;
    this.navToAnnotation(annotation.uuid);
  }
  // findPage(filters = {}) {
  //   let ret = null;
  //   for (let page of this.pages) {
  //     let foundPage = true;
  //     for (let k in filters) {
  //       if (page[k] !== filters[k]) {
  //         foundPage = false;
  //         break;
  //       }
  //     }
  //     if (foundPage) {
  //       ret = page;
  //       break;
  //     }
  //   }
  //   console.log("CHECK PAGE?", ret);
  //   return ret;
  // }
  // async init() {
  //   // this._documentSet = documentSet;
  //   // console.log("DOCUMENT SET ini?", documentSet);
  // }

  createPageKey(pageNumber) {
    return `${this.uid}-${pageNumber}`;
  }
  // async addPageInstance(page) {
  //   if (!page.number) throw new Error("Page number not found.");
  //   let pageKey = this.createPageKey(page.number);
  //   if (this.exists(pageKey)) return this.getInstance(pageKey);
  //   let document = new DocumentUiInstanceDomain(this, pageKey, page);
  //   await document.init();
  //   this.addInstance(pageKey, document);
  //   return document;
  // }
  pageInstanceExists({ number }) {
    if (!number) throw new Error("Page number not found.");
    let pageKey = this.createPageKey(number);
    return this.exists(pageKey);
  }
  updatePageInstance(page) {
    let pageKey = this.createPageKey(page.number);
    if (!this.exists(pageKey)) return false;
    let document = this.getInstance(pageKey);
    document.update(page);
    return document;
  }
  addPageInstance(page, options = {}) {
    if (!page.number) throw new Error("Page number not found.");

    let pageKey = this.createPageKey(page.number);
    if (this.exists(pageKey)) {
      let document = this.getInstance(pageKey);
      document.update(page);
      return document;
    }

    let document = new DocumentUiInstanceDomain(this, pageKey, page);
    document.init();
    this.addInstance(pageKey, document);
    return document;
  }
  removePageInstance(page) {
    if (!page.number) throw new Error("Page number not found.");
    // if (this.currentPageNumber === page.number) return false;
    let pageKey = this.createPageKey(page.number);
    this._documentSet.pages = this._documentSet.pages.filter(
      (p) => p.number !== page.number
    );
    return this.exists(pageKey) ? this.removeInstance(pageKey) : false;
  }
  async updatePageInstances(pages, options = {}) {
    // update the raw document set
    let pageKeys = this._documentSet.pages.map((page) =>
      this.createPageKey(page.number)
    );

    // Remove all the instances of the current pages
    options.reset &&
      this.pages.forEach((page) => this.removePageInstance(page));

    let updatedPages = pages
      .sort((a, b) => a.number > b.number)
      .map((page) => {
        let ret = null;
        if (this.pageInstanceExists(page)) ret = this.updatePageInstance(page);
        else {
          ret = this.addPageInstance(page, { remove: true });
          this._documentSet.pages.push(page);
        }

        // this.currentPage &&
        //   page.documentUuid === this.currentPage.documentUuid &&
        //   this.currentPage.update(page);

        return ret;
      });
    this._documentSet.pages.sort((a, b) => a.number > b.number);

    let pageNumber = options.pageNumber || null;
    pageNumber && this.navToPage(pageNumber, { refresh: true });
    // this.refreshCurrentAnnotation();
    return updatedPages;
  }

  updateAnnotationInstance(annotation) {
    if (!this.annotations.exists(annotation.uuid)) return false;
    let annotationInstance = this.getAnnotation(annotation.uuid);
    annotationInstance.updateInstance(annotation);
    return annotationInstance;
  }
  removeAnnotationInstance(annotation) {
    // Hacky fix to make sure this is the correct annotation
    if (this.annotations.exists(annotation.uuid)) {
      let currAnnotation = this.getAnnotation(annotation.uuid);
      if (currAnnotation.positionIndex === annotation.positionIndex)
        this.annotations.removeInstance(annotation.uuid);
    }
    // if (!page.number) throw new Error("Page number not found.");
    // // if (this.currentPageNumber === page.number) return false;
    // let pageKey = this.createPageKey(page.number);
    // this._documentSet.pages = this._documentSet.pages.filter(
    //   (p) => p.number !== page.number
    // );
    // return this.exists(pageKey) ? this.removeInstance(pageKey) : false;
    // this.annotations.exists(annotation.uuid) &&
    //   this.annotations.removeInstance(annotation.uuid);
  }

  async updateAnnotationInstances(annotations, options = {}) {
    // update the raw document set
    // let pageKeys = this._documentSet.pages.map((page) =>
    //   this.createPageKey(page.number)
    // );
    // const updatedAnnotations = annotations.map((annotation) =>
    //   this.annotations.exists(annotation.uuid)
    //     ? this.annotations.getInstance(annotation.uuid)
    //     : this.addAnnotationInstance(annotation)
    // );
    // Remove all the instances of the current pages
    // console.log("addPageData update instances");
    // this.annotations
    //   .filter((a) => a.type === "FIELD")
    //   .forEach((a) => console.log(a.documentPositionIndex));

    options.reset &&
      this.annotations
        .filter(
          (annotation) => annotation.type === constants.ANNOTATION_TYPE_FIELD
        )
        .forEach((annotation) => this.removeAnnotationInstance(annotation));

    let updatedAnnotations = annotations
      //.sort((a, b) => a.number > b.number)
      .map((annotation) => {
        let ret = null;
        if (this.annotations.exists(annotation.uuid))
          ret = this.updateAnnotationInstance(annotation);
        else if (
          this._currentAnnotation &&
          this._currentAnnotation.uuid === annotation.uuid
        ) {
          this._currentAnnotation.updateInstance(annotation);
          this.annotations.addInstance(
            this._currentAnnotation.uuid,
            this._currentAnnotation
          );
          ret = this._currentAnnotation;
        } else {
          ret = this.addAnnotationInstance(annotation);
        }
        // if (
        //   this._currentAnnotation &&
        //   this._currentAnnotation.uuid === ret.uuid
        // ) {
        //   console.log(
        //     "UPDATE CURRENT ANNOTATION!!!",
        //     this._currentAnnotation._tid,
        //     ret._tid
        //   );
        //   this._currentAnnotation = ret;
        // }

        return ret;
      });

    //this._documentSet.pages.sort((a, b) => a.number > b.number);

    // let pageNumber = options.pageNumber || null;
    // pageNumber && this.navToPage(pageNumber, { refresh: true });

    // this.refreshCurrentAnnotation();
    return updatedAnnotations;
  }
  addAnnotationInstance(annotation) {
    let changed = false;
    // Please check compareAnnotationValues, should it be annotation.values?
    if (
      annotation.updatedValue !== null &&
      annotation.updatedValue !== undefined &&
      annotation.updatedValue !== annotation.value
    )
      changed = true;
    else if (
      annotation.updatedValues &&
      !this.dm.manualEntry.compareAnnotationValues(
        annotation.updatedValues,
        annotation.values
      )
    )
      changed = true;
    let documentAnnotation = new DocumentSetAnnotationUiInstanceDomain(
      this.annotations,
      this,
      annotation.uuid,
      {
        uuid: annotation.uuid,
        name: annotation.name,
        label: annotation.label,
        type: annotation.type,
        fieldType: annotation.fieldType || null,
        value: changed ? annotation.updatedValue : annotation.value,
        valueSelections:
          annotation.valueSelections && annotation.valueSelections.length
            ? annotation.valueSelections
            : null,
        valueSelectionEditable:
          annotation.valueSelections &&
          annotation.valueSelections.length &&
          annotation.valueSelectionEditable
            ? true
            : false,
        // questionFields:
        //   annotation.questionFields && annotation.questionFields.length
        //     ? this.parseQuestionFields(annotation.questionFields)
        //     : null,
        values: annotation.updatedValues || annotation.values || null,
        selectionQuestionAnnotations:
          annotation.selectionQuestionAnnotations &&
          annotation.selectionQuestionAnnotations.length
            ? annotation.selectionQuestionAnnotations
            : null,
        selectionsAnnotationUuid: annotation.selectionsAnnotationUuid || null,
        selections:
          annotation.selections && annotation.selections.length
            ? annotation.selections
            : null,
        positionIndex: annotation.positionIndex,
        documentPositionIndex: annotation.documentPositionIndex,
        pageNumber: annotation.documentPositionIndex || null,
        documentUuid: annotation.documentUuid || null,
        offset: annotation.offset || null,
        parentAnnotationUuid: annotation.parentAnnotationUuid,
        metadata: {
          changed,
          reject: annotation.reject || false,
          searchable: annotation.searchable || false,
          required: annotation.required || false,
          valid: annotation.valid || false,
          state:
            annotation.state ||
            (annotation.valid
              ? this.dm.manualEntry.STATE_REVIEW
              : this.dm.manualEntry.STATE_QUEUED),
        },
      }
    );
    documentAnnotation.init();
    this.annotations.addInstance(annotation.uuid, documentAnnotation);
    return documentAnnotation;
  }
  addAnnotationInstances() {
    for (let annotation of this._documentSet.annotations) {
      this.addAnnotationInstance(annotation);
    }
  }
  clearPageInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
  initInitialValues() {
    let ret = {
      page: 1,
      annotation: { key: null, type: null },
    };
    let annotation = this.nextAnnotation;
    if (!annotation) {
      // There are no more annotations to complete
      // Just get the last visible annotation
      let annotations = this.findAnnotations({
        type: constants.ANNOTATION_TYPE_FIELD,
        sortBy: constants.SORT_BY_LAYOUT,
      });
      if (annotations.length) annotation = annotations[annotations.length - 1];
    }
    let page = this.getPage(annotation.pageNumber);
    ret = {
      page: page,
      annotationUuid: annotation.uuid,
    };
    return ret;
  }
}
class DocumentImageUiInstanceDomain extends R14.Domain {
  constructor(documentSet, document, image) {
    super();
    this._documentSet = documentSet;
    this._document = document;
    this._image = image;
  }
  update(image) {
    this._image = image;
  }
  get url() {}
}
class DocumentUiInstanceDomain extends R14.Domain {
  constructor(documentSet, key, page) {
    super();
    this.IMAGE_STORAGE_MODE_DATASET =
      this.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_DATASET;
    this.IMAGE_STORAGE_MODE_STORAGE =
      this.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_STORAGE;
    this._key = key;
    this._page = page;
    this._image = new DocumentImageUiInstanceDomain(
      documentSet,
      this,
      page.image
    );
    this._documentSet = documentSet;
    this._annotationLayout = null;
    this._padding = 32;
    this._scrollPosition = { x: 0, y: 0 };
    this._currAnnotationInitialized = false;
    this.documentRef = React.createRef();
    this._metadata = {
      state: this.dm.manualEntry.STATE_QUEUED,
    };
    this.state = {
      layout: false,
      height: 0,
      width: 0,
      scale: 1,
      zoomLevel: 1,
      annotate: false,
      annotationType: null,
      screenLayoutHeight: null,
      screenLayoutWidth: null,
      currAnnotation: null,
      rotate: page.image.rotate || 0,
      rotateUpdated: false,
      number: page.number,
      // bottomSheetHeight: null,
      // bottomSheetMode:
      //   this.dm.documentTemplateAnnotation.BOTTOM_SHEET_SIZE_MODE_RESTORE,
    };
  }
  update(page) {
    this._page = page;
    let rotateState = this.state.rotate || 0;
    let imageRotate = page.image.rotate || 0;

    this.setState({
      rotate: rotateState || imageRotate,
      rotateUpdated: rotateState !== imageRotate,
    });
    this._image.update(page.image);
  }
  remove() {
    this._key && this._documentSet.removeInstance(this._key);
  }
  get rotateUpdated() {
    return this.state.rotateUpdated;
  }
  get key() {
    return this._key;
  }
  get number() {
    return this.state.number;
  }
  get documentUuid() {
    return this._page.documentUuid;
  }
  get imageStorageMode() {
    return this._documentSet.documentImageStorageMode;
  }
  get documentSet() {
    return this._documentSet;
  }
  get scale() {
    return this.state.scale;
  }
  get scrollPosition() {
    return this._scrollPosition;
  }
  get layout() {
    return this.state.layout;
  }
  get annotationType() {
    return this.state.annotationType;
  }
  get name() {
    return (
      this._page.name ||
      (this._page.manualEntryDocument
        ? this._page.manualEntryDocument.name
        : null)
    );
  }
  get project() {
    return this._page ? this._page.project : null;
  }
  get image() {
    return this._page ? this._page.image : null;
  }
  get bottomSheet() {
    return {
      height: this.state.bottomSheetHeight,
      mode: this.state.bottomSheetMode,
    };
  }
  get metadata() {
    return this._metadata;
  }
  get padding() {
    return this._padding;
  }
  get annotationLayout() {
    return this._annotationLayout;
  }
  get annotations() {
    return this._documentSet.findAnnotations({
      pageNumber: this.number,
      sortBy: constants.SORT_BY_LAYOUT,
    });
  }
  get currentAnnotation() {
    return this.state.currAnnotation &&
      this.state.currAnnotation.uuid &&
      this.state.currAnnotation.uuid === this._currentAnnotation.uuid
      ? this._currentAnnotation
      : null;
    // return this.state.currAnnotation
    //   ? this._documentSet.getAnnotation(this.state.currAnnotation.uuid)
    //   : null;
  }
  get currentAnnotationInitialized() {
    return this._currentAnnotationInitialized ? true : false;
  }
  setMetadata(metadata) {
    this._metadata = metadata;
  }
  handleComponentMount() {
    this.initCurrentAnnotation();
  }
  handleComponentUpdate() {
    this.initCurrentAnnotation();
  }
  initCurrentAnnotation(options = {}) {
    if (
      !this.currentAnnotation ||
      !this.currentAnnotation.inPage(this.number) ||
      (!options.force && this.currentAnnotationInitialized)
    )
      return true;

    let annotation = this.currentAnnotation;

    if (!this.documentRef || !this.documentRef.current) return false;
    let pageHeight = this.state.height;
    let pageWidth = this.state.width;

    if (!pageHeight || !pageWidth) return false;

    // let size = this.calculateAnnotationSize(annotation);

    let pos = annotation.sizeExists
      ? this.calculateAnnotationPosition(annotation)
      : annotation.parentAnnotation && annotation.parentAnnotation.sizeExists
      ? this.calculateAnnotationPosition(annotation.parentAnnotation)
      : null;

    // let scrollPos = this.scrollPosition;

    if (pos !== null) {
      //   let width =
      //   event.nativeEvent && event.nativeEvent.layout
      //     ? event.nativeEvent.layout.width
      //     : null;
      // let imageWidth = this.document.image.width;
      // let imageHeight = this.document.image.height;
      // if (!width || !imageWidth || !imageHeight) return false;

      // // Add 16 pixels padding
      // let annotationWidth = annotation.width * imageWidth + 32;
      // let annotationHeight = annotation.height * imageHeight + 32;
      // let scrollTo = {};

      // let annotationPreviewScale = width / annotationWidth;

      // let annotationPreviewInitialScroll = {
      //   x: annotation.left * annotationPreviewScale * imageWidth + 16,
      //   y: annotation.top * annotationPreviewScale * imageHeight + 16,
      //   animated: false,
      // };
      // setTimeout(async () => {
      //   this.documentRef &&
      //     this.documentRef.current &&
      //     this.documentRef.current.scrollTo(annotationPreviewInitialScroll);
      // }, 100);
      // this.setState({ annotationPreviewScale, annotationPreviewInitialScroll });

      let top = pos.top * this.state.zoomLevel + this.padding;
      // let right = pos.right * this.state.zoomLevel + this.padding;
      // let bottom = pos.bottom * this.state.zoomLevel + this.padding;
      let left = pos.left * this.state.zoomLevel + this.padding;

      // let shouldScroll =
      //   top < scrollPos.y ||
      //   bottom > scrollPos.y + pageHeight ||
      //   left < scrollPos.x ||
      //   right > scrollPos.x + pageWidth;
      this.scrollTo({
        x: left - this.padding,
        y: top - this.padding,
        // animated: true,
        animated: false,
      });
    }

    this._currentAnnotationInitialized = true;
  }
  isFullPageAnnotation(annotation) {
    return (
      annotation.sizeExists &&
      annotation.top === 0 &&
      annotation.left === 0 &&
      annotation.height === 1 &&
      annotation.width === 1
    );
  }
  isCurrentAnnotation(annotation) {
    return this.currentAnnotation &&
      this.currentAnnotation.uuid === annotation.uuid
      ? true
      : false;
  }
  setCurrentAnnotation(annotation) {
    if (this.isCurrentAnnotation(annotation)) return true;
    // Should it replace the current annotation?
    let currAnnotation = null;
    if (annotation.inPage(this.number))
      currAnnotation = {
        uuid: annotation.uuid,
        name: annotation.name,
        documentUuid: annotation.documentUuid,
      };
    this._currentAnnotationInitialized = false;
    this._currentAnnotation = annotation;
    this.setState({
      currAnnotation,
    });
    return true;
  }

  init() {
    // throw new Error("HERE");
  }
  calculateAnnotationPosition(annotation) {
    if (!annotation.sizeExists) return null;

    let size = this.calculateAnnotationSize(annotation);
    let top = annotation.top * this.image.height * this.state.scale;
    let left = annotation.left * this.image.width * this.state.scale;

    return {
      top,
      left,
      bottom: top + size.height,
      right: left + size.width,
    };
  }
  calculateAnnotationSize(annotation) {
    return {
      width: annotation.width * this.image.width * this.state.scale,
      height: annotation.height * this.image.height * this.state.scale,
    };
  }
  calculateScale(height, width, mode) {
    let scale = 1;
    let imageHeight = this._page.image.height;
    let imageWidth = this._page.image.width;

    if (height && width && imageWidth && imageHeight) {
      switch (mode) {
        case this.dm.manualEntry.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_COVER:
          scale = width / imageWidth;
          break;
        case this.dm.manualEntry.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_CONTAIN:
          if (imageHeight > imageWidth) {
            scale = height / imageHeight;
            if (imageWidth * scale > width) scale = width / imageWidth;
          } else {
            scale = width / imageWidth;
            if (imageHeight * scale > height) scale = height / imageHeight;
          }
          break;
      }
    }

    return scale;
  }
  scrollTo(params) {
    this.documentRef.current && this.documentRef.current.scrollTo(params);
  }
  setAnnotationLayout(annotationLayout) {
    this._annotationLayout = annotationLayout;
  }
  getAnnotationLayout() {
    return this._annotationLayout;
  }
  setAnnotationMode(annotationType) {
    this.setState({
      annotate: annotationType ? true : false,
      annotationType: annotationType || null,
    });
    this._annotationLayout = null;
  }
  disableAnnotationMode() {
    this.setAnnotationMode(null);
  }
  updateScrollViewLayout(layout) {
    let { height, width } = layout;

    // Scale available based on padding
    height = height - this.padding * 2;
    width = width - this.padding * 2;

    this.setState({
      layout: true,
      height,
      width,
      scale: this.calculateScale(
        height,
        width,
        this.documentSet.initialDocumentScaleMode
      ),
      //prevScale: this.state.scale
    });
  }
  updateScroll(scrollValues) {
    this._scrollPosition = scrollValues;
  }
  updateScreenLayout({ height, width }) {
    let nState = {
      screenLayoutHeight: height,
      screenLayoutWidth: width,
    };
    if (!this.state.bottomSheetMode) {
      // Init the bottom sheet
      screenLayoutHeight: {
      }
      // nState.bottomSheetMode =
      //   this.dm.documentTemplateAnnotation.BOTTOM_SHEET_SIZE_MODE_RESTORE;
      // nState.bottomSheetHeight = height / 3;
    }
    this.setState(nState);
  }
  setBottomSheetHeight(height) {
    this.setState({
      bottomSheetHeight: height,
    });
  }
  setZoomLevel(zoomLevel) {
    this.setState({
      zoomLevel,
    });
  }
  zoomIn() {
    let zoomLevel = this.state.zoomLevel;
    // zoomLevel += 0.1;
    // if (zoomLevel < 2) this.setZoomLevel(zoomLevel);

    zoomLevel += 0.2;
    this.setZoomLevel(zoomLevel);
  }
  zoomOut() {
    let zoomLevel = this.state.zoomLevel;
    // zoomLevel -= 0.1;
    // if (zoomLevel > -2) this.setZoomLevel(zoomLevel);
    zoomLevel -= 0.2;
    this.setZoomLevel(zoomLevel);
  }
  zoomReset() {
    this.setZoomLevel(1);
    this.initCurrentAnnotation({ force: true });
  }
  scrollInDirection(direction, amount = 10) {
    let { x, y } = this._scrollPosition;
    switch (direction) {
      case "up":
        y -= amount;
        break;
      case "down":
        y += amount;
        break;
      case "left":
        x -= amount;
        break;
      case "right":
        x += amount;
        break;
    }
    this.scrollTo({ x, y });
  }
  scrollUp() {
    this.scrollInDirection("up");
  }
  scrollDown() {
    this.scrollInDirection("down");
  }
  scrollLeft() {
    this.scrollInDirection("left");
  }
  scrollRight() {
    this.scrollInDirection("right");
  }
  rotateRight() {
    return this._rotate(90);
  }
  rotateLeft() {
    return this._rotate(-90);
  }
  _rotate(amount) {
    let rotate = this.state.rotate || 0;
    rotate += amount;
    if (rotate < -270) rotate = 360 + (rotate % 360);
    if (rotate > 270) rotate = rotate % 360;
    let rotateUpdated =
      this._documentSet.hasAppFeature("enableDocumentRotate") &&
      rotate !== this._page.image.rotate;
    this.setState({ rotate: rotate, rotateUpdated });
  }
  handleSelectAnnotationArea(selectArea) {
    let layout = null;
    if (selectArea) {
      let { x, y, height, width } = selectArea;
      let imageScaleHeight =
        this.image.height * this.state.scale * this.state.zoomLevel;
      let imageScaleWidth =
        this.image.width * this.state.scale * this.state.zoomLevel;
      layout = {
        x: x / imageScaleWidth,
        y: y / imageScaleHeight,
        height: height / imageScaleHeight,
        width: width / imageScaleWidth,
      };
    }
    this.setAnnotationLayout(layout);
  }
  getAnnotationColorByType(type) {
    return this.dm.documentTemplateAnnotation.getColorByType(type);
  }
}
class DocumentSetAnnotationsUiDomain extends R14.DomainInstances {
  constructor(documentsSet) {
    super();
    this._documentSet = documentsSet;
  }
  async instance(uuid, options = {}) {
    if (this.exists(uuid)) return this.getInstance(uuid);
    throw new Error("Error finding instance?");
    // let DocumentSetAnnotation = new DocumentSetAnnotationUiInstanceDomain(
    //   this,
    //   this._documentSet,
    //   uuid,
    //   options
    // );
    // await DocumentSetAnnotation.init();
    // this.addInstance(uuid, DocumentSetAnnotation);
    // return DocumentSetAnnotation;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}
class DocumentSetAnnotationUiInstanceDomain extends R14.Domain {
  constructor(annotations, documentSet, uuid, annotation = {}) {
    super();
    //this._tid = Math.random().toString(36).substr(2, 9);
    this._annotation = annotation;
    this._annotations = annotations;
    this._documentSet = documentSet;
    this._uuid = uuid;
    this._type = annotation.type || null;
    this._name = annotation.name || null;
    this._label = annotation.label || null;
    this._value = annotation.value || null;
    this._parentAnnotationUuid = annotation.parentAnnotationUuid || null;
    this._sizeExists = annotation.offset ? true : false;
    this._valueSelections =
      annotation.valueSelections && annotation.valueSelections.length
        ? annotation.valueSelections
        : null;
    this._valueSelectionEditable = annotation.valueSelectionEditable || false;

    this._selectionQuestionAnnotations =
      annotation.selectionQuestionAnnotations &&
      annotation.selectionQuestionAnnotations.length
        ? annotation.selectionQuestionAnnotations
        : null;

    this._selectionsAnnotationUuid =
      annotation.selectionsAnnotationUuid || null;
    // this._questionFields =
    //   annotation.questionFields && annotation.questionFields.length
    //     ? annotation.questionFields
    //     : null;
    this._values =
      annotation.values && annotation.values.length ? annotation.values : null;
    this._editForm = null;
    this._documentUuid = annotation.documentUuid;
    this._positionIndex = annotation.positionIndex;
    this._documentPositionIndex = annotation.documentPositionIndex;
    this._pageNumbers =
      annotation.pageNumbers || annotation.pageNumber
        ? [annotation.pageNumber]
        : [];
    let offset = annotation.offset || {};
    this.state = {
      ...offset,
      metadata: annotation.metadata || {},
    };
  }
  updateInstance(annotation) {
    this._documentUuid = annotation.documentUuid;
    this._positionIndex = annotation.positionIndex;
    this._documentPositionIndex = annotation.documentPositionIndex;
    let pageNumbers = [];
    if (annotation.pageNumbers) pageNumbers = annotation.pageNumbers;
    else if (annotation.pageNumber) pageNumbers.push(annotation.pageNumber);
    else if (annotation.documentPositionIndex)
      pageNumbers.push(annotation.documentPositionIndex);
    if (pageNumbers.length) this._pageNumbers = pageNumbers;
    return this;
  }
  init() {}
  // const createFieldKey = (field) =>
  // `_mdsdqf_${field.path
  //   .map((uuid) => annotationUUidKeyMap[uuid])
  //   .filter((key) => (key ? true : false))
  //   .join("-")}`;
  generateQuestionFields(
    questionFields = null,
    parentField = null,
    selectionsAnnotationsUuidMap = null,
    fieldUuidKeyMap = null
  ) {
    let ret = [];
    const createFieldKey = (field) =>
      `_mdsdqf-${field.path
        .map((uuid) => fieldUuidKeyMap[uuid])
        .filter((key) => (key ? true : false))
        .join("-")}`;
    if (!questionFields)
      questionFields = this._annotations
        .filter(
          (annotation) =>
            [
              //constants.ANNOTATION_TYPE_SELECTIONS,
              constants.ANNOTATION_TYPE_QUESTION,
            ].includes(annotation.type) ||
            (annotation.type === constants.ANNOTATION_TYPE_FIELD &&
              annotation.uuid === this.uuid)
        )
        .map((annotation) => annotation._annotation);
    if (!selectionsAnnotationsUuidMap)
      selectionsAnnotationsUuidMap = this.documentSet
        .findAnnotations({
          type: constants.ANNOTATION_TYPE_SELECTIONS,
        })
        .reduce(
          (ret, annotation) => ({
            ...ret,
            [annotation.uuid]: annotation._annotation,
          }),
          {}
        );
    if (!fieldUuidKeyMap)
      fieldUuidKeyMap = questionFields.reduce(
        (ret, field, idx) =>
          field.uuid in ret ? ret : { ...ret, [field.uuid]: `a${idx}` },
        {}
      );

    ret = questionFields
      .filter((field) =>
        parentField
          ? parentField.selectionQuestionAnnotations.some(
              ({ questionAnnotationUuids }) =>
                questionAnnotationUuids.includes(field.uuid)
            )
          : field.uuid === this.uuid &&
            field.type === constants.ANNOTATION_TYPE_FIELD
      )
      .map((annotation) => {
        let field = {
          uuid: annotation.uuid,
          required: annotation.metadata.required || false,
          searchable: annotation.metadata.searchable || false,
          label: annotation.label,
          type: annotation.type,
          fieldType: annotation.fieldType,
          offset: annotation.offset,
          path: parentField
            ? [...parentField.path, annotation.uuid]
            : [annotation.uuid],
          selectionQuestionAnnotations: annotation.selectionQuestionAnnotations,
          selectionsAnnotationUuid: annotation.selectionsAnnotationUuid,
        };
        // Create the unique field key
        field.key = createFieldKey(field);
        field.parentKey = parentField ? parentField.key : null;
        // Add selections
        if (
          annotation.selectionsAnnotationUuid &&
          selectionsAnnotationsUuidMap[annotation.selectionsAnnotationUuid]
        ) {
          field.selections = selectionsAnnotationsUuidMap[
            annotation.selectionsAnnotationUuid
          ].selections.map((selection, idx) => ({
            ...selection,
            key: `${field.key}-s${idx}`,
          }));
        }
        let parentSelectionQuestionAnnotations = parentField
          ? parentField.selectionQuestionAnnotations.find(
              ({ questionAnnotationUuids }) =>
                questionAnnotationUuids.includes(field.uuid)
            )
          : null;
        if (parentSelectionQuestionAnnotations) {
          let parentSelection = parentField.selections.find(
            ({ uuid }) =>
              uuid === parentSelectionQuestionAnnotations.selectionUuid
          );
          if (parentSelection) field.parentSelectionKey = parentSelection.key;
        }

        field.questionFields = field.selectionQuestionAnnotations
          ? this.generateQuestionFields(
              questionFields,
              field,
              selectionsAnnotationsUuidMap,
              fieldUuidKeyMap
            )
          : [];
        field.childKeys = [];
        field.questionFields.forEach(
          (childField) =>
            !field.childKeys.includes(childField.key) &&
            field.childKeys.push(childField.key, ...childField.childKeys)
        );
        return field;
      });

    return ret;
  }
  // parseQuestionFields(questionFields, parentField = null) {
  //   const getChildSelectionUids = (selection) => {
  //     let childUuids = [];
  //     questionFields.forEach((field) => {
  //       if (field.selections && field.parentSelectionUuid === selection.uuid) {
  //         field.selections.forEach((childSelection) => {
  //           childUuids.push(
  //             childSelection.uuid,
  //             ...getChildSelectionUids(childSelection)
  //           );
  //         });
  //       }
  //     });
  //     return childUuids;
  //   };

  //   return questionFields
  //     .filter((field) =>
  //       parentField
  //         ? parentField.selections.some(
  //             (uuid) => uuid === field.parentSelectionUuid
  //           )
  //         : !field.parentSelectionUuid
  //     )
  //     .map((field) => {
  //       field.questionFields = this.parseQuestionFields(questionFields, field);
  //       field.childUuids = [];
  //       field.questionFields.forEach((childField) =>
  //         field.childUuids.push(childField.uuid, ...childField.childUuids)
  //       );
  //       if (parentField) field.parentUuid = parentField.uuid;
  //       // if (field.selections)
  //       //   field.selections = field.selections.map((selection) => {
  //       //     selection.childUuids = getChildSelectionUids(selection);
  //       //     return selection;
  //       //   });
  //       return field;
  //     });
  // }
  get uuid() {
    return this._uuid;
  }
  get positionIndex() {
    return this._positionIndex;
  }
  get documentPositionIndex() {
    return this._documentPositionIndex;
  }
  set positionIndex(positionIndex) {
    this._positionIndex = positionIndex;
    return this;
  }
  set documentPositionIndex(documentPositionIndex) {
    this._documentPositionIndex = documentPositionIndex;
    return this;
  }
  get parentAnnotationUuid() {
    return this._parentAnnotationUuid;
  }
  get parentAnnotation() {
    return this.parentAnnotationUuid
      ? this.documentSet
          .findAnnotations({
            parentAnnotationUuid: this.parentAnnotationUuid,
          })
          .at(0)
      : null;
  }
  get type() {
    return this._type;
  }
  get name() {
    return this._name;
  }
  get label() {
    return this._label;
  }
  get value() {
    return this._value;
  }
  get valueSelections() {
    return this._valueSelections || [];
  }
  get valueSelectionEditable() {
    return this._valueSelectionEditable;
  }
  get selectionQuestionAnnotations() {
    return this._selectionQuestionAnnotations || [];
  }
  get selectionsAnnotationUuid() {
    return this._selectionsAnnotationUuid || null;
  }
  get values() {
    return this._values || [];
  }
  get metadata() {
    return this.state.metadata;
  }
  get sizeExists() {
    return this._sizeExists;
  }
  get valueExists() {
    return this.value === undefined || this.value === null || this.value === ""
      ? false
      : true;
  }
  get documentUuid() {
    return this._documentUuid;
  }
  get pageNumber() {
    return this._pageNumbers.length ? this._pageNumbers[0] : null;
  }
  set pageNumber(pageNumber) {
    this._pageNumbers = [pageNumber];
    return this;
  }
  get page() {
    let pageNumber = this.pageNumber;
    if (!pageNumber) return null;
    return this._documentSet.getPage(pageNumber);
  }
  get documentSet() {
    return this._documentSet;
  }
  get height() {
    return this.state.height;
  }
  get width() {
    return this.state.width;
  }
  get top() {
    return this.state.top;
  }
  get left() {
    return this.state.left;
  }
  get editForm() {
    return this._editForm;
  }
  setMetadata(metadata) {
    this.setState({ metadata });
  }
  inPage(pageNumber) {
    return this._pageNumbers.includes(pageNumber);
  }
  get pageNumbers() {
    return this._pageNumbers;
  }
  isValueSelectionOther(value) {
    return value === "__r14_valueSelectionEditable";
  }
  addValueKeys(questionFields, values) {
    return values.map((value) => {
      // find key and parent key
      let key = null;
      let parentKey = null;
      let selectionKey = null;
      let fields = questionFields;
      value.questionAnnotationUuidPath.forEach((uuid) => {
        let field = fields.find((f) => f.uuid === uuid);
        if (key && !parentKey) parentKey = key;
        key = field.key;
        fields = field.questionFields;
        if (value.selectionUuid && field.selections) {
          let selection = field.selections.find(
            (selection) => selection.uuid === value.selectionUuid
          );
          selectionKey = selection ? selection.key : null;
        }
      });
      return { ...value, key, parentKey, selectionKey };
    });
  }
  initEditForm() {
    // let title = this.name;
    // let label = this.name;
    if (!this._editForm)
      this._editForm = new DocumentSetAnnotationEditFormUiDomain(this);
    let formData = {
      title: this.label || this.name,
      label: this.label || this.name,
      required: this.metadata.required,
      document: this.page,
      documentSet: this.documentSet,
      annotation: this,
      values: {
        documentSetUid: this.documentSet.uid,
        value: this.value,
        uuid: this.uuid,
        type: this.type,
      },
    };
    if (this.parentAnnotationUuid) {
      let parentAnnotation = this.documentSet
        .findAnnotations({
          parentAnnotationUuid: this.parentAnnotationUuid,
          debug: true,
        })
        .at(0);
      if (parentAnnotation && (parentAnnotation.label || parentAnnotation.name))
        formData.title = parentAnnotation.label || parentAnnotation.name;
    }
    if (this.selectionsAnnotationUuid) {
      formData.questionFields = this.generateQuestionFields();
      // formData.annotation = this;
      // formData.selectionQuestionAnnotations = this.selectionQuestionAnnotations;
      // formData.questionAnnotations = this.documentSet.findAnnotations({
      //   type: constants.ANNOTATION_TYPE_QUESTION,
      // });
      // formData.selectionsAnnotations = this.documentSet.findAnnotations({
      //   type: constants.ANNOTATION_TYPE_SELECTIONS,
      // });
    }
    if (this.questionFields && this.questionFields.length) {
      formData.questionFields = [...this.questionFields];
    }
    if (formData.questionFields && this.values && this.values.length) {
      formData.values.values = this.addValueKeys(
        formData.questionFields,
        this.values
      );
      // formData.values.values = this.values.map((value) => {
      //   // find key and parent key
      //   let key = null;
      //   let parentKey = null;
      //   let selectionKey = null;
      //   console.log("CHECK QUESTION FIELDS", formData.questionFields);
      //   let questionFields = formData.questionFields;
      //   value.questionAnnotationUuidPath.forEach((uuid) => {
      //     let field = questionFields.find((f) => f.uuid === uuid);
      //     if (key && !parentKey) parentKey = key;
      //     key = field.key;
      //     questionFields = field.questionFields;
      //     if (value.selectionUuid && field.selections) {
      //       let selection = field.selections.find((selection) => selection.uid);
      //       selectionKey = selection ? selection.key : null;
      //     }
      //   });
      //   console.log("CHECK UPDATEDE SUBMTI VALS", {
      //     ...value,
      //     key,
      //     parentKey,
      //     selectionKey,
      //   });
      //   return { ...value, key, parentKey, selectionKey };
      // });
    }
    if (this.valueSelections && this.valueSelections.length) {
      formData.valueSelections = [];
      formData.valueSelectionEditable = this.valueSelectionEditable;
      // Check if value is in values selections
      let foundSelection = false;
      for (let valueSelection of this.valueSelections) {
        if (
          typeof this.value === "string" &&
          this.value.trim().toUpperCase() === valueSelection.value.toUpperCase()
        ) {
          formData.values.valueSelection = valueSelection.value;
          // formData.values.value = valueSelection;
          foundSelection = true;
        }
        let label = valueSelection.label || valueSelection.value;
        formData.valueSelections.push({
          label: label.trim(),
          value: valueSelection.value,
        });
      }
      if (this.valueSelectionEditable) {
        formData.valueSelections.push({
          label: "Other",
          value: "__r14_valueSelectionEditable",
        });
        // if (!foundSelection) {
        //   formData.values.valueSelection = "__r14_valueSelectionEditable";
        // }
      }
    }
    return formData;
  }
  async update(values) {
    //try {
    let updateValues = await this.dm.manualEntry.updateDocumentSetAnnotation(
      values,
      { annotation: this._annotation }
    );
    // let annotation = this._annotations.getInstance(this._uuid);
    let { uuid, type } = values;
    switch (type) {
      case constants.ANNOTATION_TYPE_FIELD:
        let reject =
          "reject" in updateValues ? updateValues.reject : this.metadata.reject;

        let changed = this.metadata.changed || false;
        if (!changed) {
          if (
            "updatedValues" in updateValues &&
            !this.dm.manualEntry.compareAnnotationValues(
              this._values,
              updateValues.updatedValues
            )
          )
            changed = true;
          else if (
            "updatedValue" in updateValues &&
            updateValues.updatedValue !== null &&
            updateValues.updatedValue !== this._value
          )
            changed = true;
        }

        if (changed) {
          this._value = updateValues.updatedValue;
          this._values = updateValues.updatedValues;
        }

        if (updateValues.state)
          this.setMetadata({ state: updateValues.state, changed, reject });
        break;
      default:
        throw new Error(`Document Set Update Error: Unknown type '${type}'.`);
    }
    return await this.documentSet.update();
  }
}

class DocumentSetAnnotationEditFormUiDomain extends R14.Domain {
  constructor(annotation) {
    super();
    this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
    this._annotation = annotation;
    this._previewDocument = new DocumentUiInstanceDomain(
      annotation.documentSet,
      null,
      annotation.page
    );
    this._changed = false;
    this.keyMapper = null;
  }
  get annotation() {
    return this._annotation;
  }
  get previewDocument() {
    return this._previewDocument;
  }
  get documentSet() {
    return this.annotation.documentSet;
  }
  get documentAnnotationForm() {
    return this.ui.form("documentAnnotationForm");
  }
  get changed() {
    return this._changed;
  }
  get valueSelections() {
    return this._annotation.valueSelections || [];
  }
  get valueSelectionsExist() {
    return this._annotation.valueSelections &&
      this._annotation.valueSelections.length
      ? true
      : false;
  }
  get radioQuestionFieldsExist() {
    return this._annotation.selectionsAnnotationUuid ? true : false;
    // get selectionQuestionAnnotations() {
    //   return this._selectionQuestionAnnotations || [];
    // }
    // get selectionsAnnotationUuid() {
    //   return this._selectionsAnnotationUuid || null;
    // }
    // let annotationForm = this.ui.form("documentAnnotationForm");
    // annotationForm.elmts.forEach((elmt) =>
    //   console.log("CHECK ELEMENT NAME", elmt)
    // );
    // console.log(
    //   "check questions",
    //   this._annotation.selectionQuestionAnnotations,
    //   this._annotation.selectionsAnnotationUuid
    // );
    // return true;
  }
  get valueSelectionEditable() {
    return this.valueSelectionsExist && this._annotation.valueSelectionEditable
      ? true
      : false;
  }

  setChanged(changed) {
    this._changed = changed;
  }
  unmount() {
    this.keyMapper && this.keyMapper.disconnect();
  }
  mount() {
    this.initKeyMapper();
  }
  initKeyMapper() {
    this.keyMapper = this.ui.keyMapper.create("documentSetAnnotationEditForm", {
      repeat: false,
    });

    // If question fields, disable accept on Enter
    if (!this.annotation.selectionsAnnotationUuid)
      this.keyMapper.addAction(
        "accept",
        ["Enter"],
        this.handleKeyMapperAction,
        {
          label: "Accept",
        }
      );
    this.keyMapper
      .onKeyDown(async ({ key }) => this.dm.manualEntry.registerKeystroke(key))
      .addAction("acceptRemaining", ["AltEnter"], this.handleKeyMapperAction, {
        label: "Accept Remaining",
      })
      .addAction(
        "rejectRemaining",
        ["ShiftCtrlEnter"],
        this.handleKeyMapperAction,
        {
          label: "Reject Remaining",
        }
      )
      .addAction("reject", ["CtrlEnter"], this.handleKeyMapperAction, {
        label: "Reject",
      })
      .addAction("previous", ["Ctrl,"], this.handleKeyMapperAction, {
        label: "Previous",
      })
      .addAction("next", ["Ctrl."], this.handleKeyMapperAction, {
        label: "Next",
      })
      .addAction(
        "previewDocumentZoomIn",
        ["ShiftCtrl}"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom In",
        }
      )
      .addAction(
        "previewDocumentZoomOut",
        ["ShiftCtrl{"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom Out",
        }
      )
      .addAction(
        "previewDocumentZoomReset",
        ["ShiftCtrl|"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom Reset",
        }
      )
      .addAction(
        "previewDocumentScrollUp",
        ["ShiftCtrlArrowUp"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Up",
        }
      )
      .addAction(
        "previewDocumentScrollDown",
        ["ShiftCtrlArrowDown"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Down",
        }
      )
      .addAction(
        "previewDocumentScrollLeft",
        ["ShiftCtrlArrowLeft"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Left",
        }
      )
      .addAction(
        "previewDocumentScrollRight",
        ["ShiftCtrlArrowRight"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Right",
        }
      )
      .addAction(
        "previewDocumentRotateLeft",
        ["ShiftCtrl<"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Rotate Left",
        }
      )
      .addAction(
        "previewDocumentRotateRight",
        ["ShiftCtrl>"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Rotate Right",
        }
      )
      .addAction("documentZoomIn", ["ShiftAlt}"], this.handleKeyMapperAction, {
        label: "Document Zoom In",
      })
      .addAction("documentZoomOut", ["ShiftAlt{"], this.handleKeyMapperAction, {
        label: "Document Zoom Out",
      })
      .addAction(
        "documentZoomReset",
        ["ShiftAlt|"],
        this.handleKeyMapperAction,
        {
          label: "Document Zoom Reset",
        }
      )
      .addAction(
        "documentScrollUp",
        ["ShiftAltArrowUp"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Up",
        }
      )
      .addAction(
        "documentScrollDown",
        ["ShiftAltArrowDown"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Down",
        }
      )
      .addAction(
        "documentScrollLeft",
        ["ShiftAltArrowLeft"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Left",
        }
      )
      .addAction(
        "documentScrollRight",
        ["ShiftAltArrowRight"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Right",
        }
      )
      .addAction(
        "documentRotateLeft",
        ["ShiftAlt<"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Left",
        }
      )
      .addAction(
        "documentRotateRight",
        ["ShiftAlt>"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Right",
        }
      )
      .addAction(
        "documentRotateSave",
        ["ShiftAltEnter"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Save",
        }
      );
    // Enter value selections
    if (this.valueSelectionsExist) {
      let kIdx = 0;
      this.valueSelections.forEach((valueSelection, idx) => {
        kIdx = idx + 1;
        if (kIdx > 12) return;
        this.keyMapper.addAction(
          `selectValueSelection${kIdx}`,
          [`F${kIdx}`],

          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
      });
      kIdx++;
      if (this.valueSelectionEditable)
        this.keyMapper.addAction(
          `selectValueSelectionOther`,
          [`F${kIdx}`],
          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
    } else if (this.radioQuestionFieldsExist) {
      for (let kIdx = 1; kIdx <= 12; kIdx++) {
        this.keyMapper.addAction(
          `selectRadioQuestionSelection${kIdx}`,
          [`F${kIdx}`],

          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
      }
    }
  }
  async handleKeyMapperAction({ e, actionName, key }) {
    e.preventDefault();
    let annotation = null;
    switch (actionName) {
      case "next":
        annotation = this.documentSet.findAnnotation({
          afterAnnotationUuid: this.annotation.uuid,
        });
        annotation && this.documentSet.navToAnnotation(annotation.uuid);
        break;
      case "previous":
        annotation = this.documentSet.findAnnotation({
          beforeAnnotationUuid: this.annotation.uuid,
        });
        annotation && this.documentSet.navToAnnotation(annotation.uuid);
        break;
      case "reject":
        if (
          !this.documentSet.hasAppFeature("disableReject") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          await this.documentAnnotationForm.submit({ reject: true });
        break;
      case "accept":
        if (this.documentSet.documentCompleteDialogVisible)
          await this.documentSet.complete();
        else this.documentAnnotationForm.submit();
        break;
      case "acceptRemaining":
        if (
          !this.documentSet.hasAppFeature("disableAcceptRemaining") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          this.documentAnnotationForm.submit({ acceptRemaining: true });
        break;
      case "rejectRemaining":
        if (
          !this.documentSet.hasAppFeature("disableRejectRemaining") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          this.documentAnnotationForm.submit({ rejectRemaining: true });
        break;
      case "previewDocumentZoomIn":
        this.previewDocument.zoomIn();
        break;
      case "previewDocumentZoomOut":
        this.previewDocument.zoomOut();
        break;
      case "previewDocumentZoomReset":
        this.previewDocument.zoomReset();
        break;
      case "previewDocumentRotateLeft":
        this.previewDocument.rotateLeft();
        break;
      case "previewDocumentRotateRight":
        this.previewDocument.rotateRight();
        break;
      case "previewDocumentScrollUp":
        this.previewDocument.scrollUp();
        break;
      case "previewDocumentScrollDown":
        this.previewDocument.scrollDown();
        break;
      case "previewDocumentScrollLeft":
        this.previewDocument.scrollLeft();
        break;
      case "previewDocumentScrollRight":
        this.previewDocument.scrollRight();
        break;
      case "documentZoomIn":
        this.documentSet.currentPage.zoomIn();
        break;
      case "documentZoomOut":
        this.documentSet.currentPage.zoomOut();
        break;
      case "documentZoomReset":
        this.documentSet.currentPage.zoomReset();
        break;
      case "documentScrollUp":
        this.documentSet.currentPage.scrollUp();
        break;
      case "documentScrollDown":
        this.documentSet.currentPage.scrollDown();
        break;
      case "documentScrollLeft":
        this.documentSet.currentPage.scrollLeft();
        break;
      case "documentScrollRight":
        this.documentSet.currentPage.scrollRight();
        break;
      case "documentRotateLeft":
        this.documentSet.currentPage.rotateLeft();
        break;
      case "documentRotateRight":
        this.documentSet.currentPage.rotateRight();
        break;
      case "documentRotateSave":
        this.documentSet.rotateDocument();
        break;
      default:
        if (
          this.valueSelectionsExist &&
          actionName.startsWith("selectValueSelection") &&
          key.startsWith("F") &&
          [2, 3].includes(key.length)
        ) {
          if (actionName === "selectValueSelectionOther") {
            this.documentAnnotationForm.elmts.valueSelection.value =
              "__r14_valueSelectionEditable";

            // this.documentAnnotationForm.elmts.valueSelection.focus({
            //   item: {
            //     label: "Other",
            //     value: "__r14_valueSelectionEditable",
            //   },
            // });
          } else {
            this.valueSelections.forEach((valueSelection, idx) => {
              idx++;
              if (key !== `F${idx}`) return;
              this.documentAnnotationForm.elmts.valueSelection.value =
                valueSelection.value;
              // this.documentAnnotationForm.elmts.valueSelection.focus({
              //   item: valueSelection,
              // });
              // this.documentAnnotationForm.elmts.valueSelection.blurItems();
            });
          }
        } else if (
          this.radioQuestionFieldsExist &&
          actionName.startsWith("selectRadioQuestionSelection") &&
          key.startsWith("F") &&
          [2, 3].includes(key.length)
        ) {
          // Try to find a radio group in the form
          let elmt = this.documentAnnotationForm.elmts.find((elmt) =>
            elmt.name.startsWith("_mdsdqf-")
          );
          if (!elmt) return;
          let item = elmt.items
            ? elmt.items.find((item, idx) => key === `F${idx + 1}`)
            : null;
          if (!item) return;
          elmt.value = item.value;
        }
    }
  }
}
