import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";

import View from "../View";
// import Text from "./Text";
// import ScrollView from "./ScrollView";
import StyleSheet from "../StyleSheet";
// import AnimatedView from "./AnimatedView";
import { Colors } from "../Theme";
import "overlayscrollbars/styles/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default forwardRef(function InfiniteList(
  {
    name = null,
    children = null,
    onScroll = null,
    onLayout = null,
    onContentSizeChange = null,
    height = null,
    autoSize = false,
    horizontal = false,
    contentStyle = null,
    overflow = null,
    overflowX = null,
    overflowY = null,
    style = null,
    onScrollEnabled = null,
    scrollEnabled = true,
    showsHorizontalScrollIndicator = true,
  },
  ref
) {
  // const ref = useRef();
  const osInstanceRef = useRef();
  const viewRef = useRef();
  const htmlRef = useRef();
  const contentHtmlRef = useRef();
  const lastContentSizeRef = useRef({
    x: null,
    y: null,
  });
  const initTriggerOnScrollRef = useRef(false);
  const lastScrollRef = useRef(null);
  const initialScrollToRef = useRef();
  const scrollToTimeoutRef = useRef(null);
  const clearScrollToTimeoutRef = () =>
    scrollToTimeoutRef.current && clearTimeout(scrollToTimeoutRef.current);
  useEffect(() => {
    return function unmount() {
      clearScrollToTimeoutRef();
    };
  }, []);
  useEffect(() => {
    if (
      scrollEnabled &&
      lastScrollRef.current &&
      lastScrollRef.current.contentOffset[axis] > 0
    ) {
      scrollTo({
        [axis]: lastScrollRef.current.contentOffset[axis],
        animated: false,
        timeout: false,
      });
      onScrollEnabled && onScrollEnabled();
    }
  }, [scrollEnabled]);
  const axis = horizontal ? "x" : "y";
  const overflowMap = {
    x: horizontal ? "auto" : overflowX || overflow || "hidden",
    y: horizontal ? "hidden" : overflowY || overflow || "auto",
  };
  const dynamicStyles = useMemo(() => {
    let heightVal = height || null;
    if (autoSize) {
      heightVal = "100%";
    }
    let scrollViewStyle = heightVal
      ? {
          overflowY: overflowMap.y,
          overflowX: overflowMap.x,
          height: heightVal,
          position: "relative",
        }
      : {
          overflowY: overflowMap.y,
          overflowX: overflowMap.x,
          position: "absolute",
          flex: 1,
          ...StyleSheet.absoluteFill,
        };
    // Need to set to display block for some kind of hacky fix.
    scrollViewStyle.display = "block";
    if (!showsHorizontalScrollIndicator) {
      scrollViewStyle["::-webkit-scrollbar"] = {
        width: 0,
        backgroundColor: "transparent",
      };
      scrollViewStyle["::-webkit-scrollbar *"] = {
        backgroundColor: "transparent",
      };
      scrollViewStyle.boxSizing = "content-box";
      // scrollViewStyle.height = 'auto';
      // scrollViewStyle.paddingBottom = 16;
    }

    return StyleSheet.create({
      scrollView: scrollViewStyle,
      disabled: {
        marginTop: `-${
          lastScrollRef.current ? lastScrollRef.current.contentOffset[axis] : 0
        }px`,
        position: "relative",
      },
      scrollViewContent: {
        flex: 1,
        zIndex: "unset",
      },
      scrollTheme: {
        boxSizing: "border-box",
        "--os-handle-bg": StyleSheet.color(Colors.onBackground).rgba(0.1),
        "--os-handle-bg-hover": StyleSheet.color(Colors.onBackground).rgba(0.2),
        "--os-handle-bg-active": StyleSheet.color(Colors.onBackground).rgba(
          0.3
        ),
        "--os-size": 10,
        "--os-padding-perpendicular": 2,
        "--os-padding-axis": 2,
        "--os-track-border-radius": 10,
        "--os-handle-interactive-area-offset": 4,
        "--os-handle-border-radius": 10,
      },
    });
  }, [
    autoSize,
    height,
    overflowX,
    overflowY,
    showsHorizontalScrollIndicator,
    scrollEnabled,
  ]);
  useImperativeHandle(
    ref,
    () => {
      return {
        scrollTo(params) {
          scrollTo(params);
        },
        scrollToEnd(params = {}) {
          scrollTo({ scrollToEnd: true, ...params });
        },
        triggerOnScroll() {
          osInstanceRef &&
            osInstanceRef.current &&
            triggerOnScroll(osInstanceRef.current);
        },
        measureInWindow(callback) {
          viewRef &&
            viewRef.current &&
            viewRef.current.measureInWindow(callback);
        },
        measure(callback) {
          viewRef && viewRef.current && viewRef.current.measure(callback);
        },
      };
    },
    []
  );
  const scrollTo = (options = {}) => {
    let params = { ...options };
    const osInstance = osInstanceRef.current;
    if (!osInstance) {
      initialScrollToRef.current = params;
      return;
    }
    const { scrollOffsetElement } = osInstance.elements();
    if (params.scrollToEnd) {
      const axis = horizontal ? "x" : "y";
      const propSize = horizontal ? "Width" : "Height";
      params[axis] =
        scrollOffsetElement[`scroll${propSize}`] +
          scrollOffsetElement[`client${propSize}`] || 0;
      delete params.scrollToEnd;
    }
    clearScrollToTimeoutRef();
    let scrollParams = {
      left: params.x || 0,
      top: params.y || 0,
      behavior: params.animated !== false ? "smooth" : "auto",
    };
    if (params.timeout === false) scrollOffsetElement.scrollTo(scrollParams);
    else
      scrollToTimeoutRef.current = setTimeout(() => {
        scrollOffsetElement.scrollTo(scrollParams);
      }, params.timeout || 250);
  };
  const handleLayout = (val) => onLayout && onLayout(val);
  const triggerOnScroll = (osInstance) => {
    if (!osInstance) {
      if (scrollEnabled) initTriggerOnScrollRef.current = true;
      return false;
    }
    if (!osInstance || !scrollEnabled) return false;
    //const { overflowAmount } = osInstance.state();
    const { scrollOffsetElement } = osInstance.elements();
    const { scrollLeft, scrollTop } = scrollOffsetElement;
    if (!htmlRef.current) return;
    const e = {
      nativeEvent: {
        contentOffset: {
          x: scrollLeft,
          y: scrollTop,
        },
        layout: {
          width: scrollOffsetElement.offsetWidth,
          height: scrollOffsetElement.offsetHeight,
        },
        contentSize: {
          width: scrollOffsetElement.scrollWidth,
          height: scrollOffsetElement.scrollHeight,
        },
      },
    };
    lastScrollRef.current = e.nativeEvent;
    onScroll && onScroll(e);
  };
  const handleContentSizeChange = (val) => {
    /** @todo this is not tested */
    const contentSize = {
      width: val.nativeEvent.layout.width,
      height: val.nativeEvent.layout.height,
    };
    if (
      contentSize.width === lastContentSizeRef.current.width &&
      contentSize.height === lastContentSizeRef.current.height
    )
      return;
    lastContentSizeRef.current = contentSize;
    onContentSizeChange &&
      onContentSizeChange(contentSize.width, contentSize.height);
  };
  const handleInitializedEvent = (osInstance) => {
    osInstanceRef.current = osInstance;
    const { scrollOffsetElement } = osInstance.elements();
    scrollOffsetElement.classList.add(
      StyleSheet.className([dynamicStyles.scrollViewContent])
    );
    // Always trigger scroll event on initial load
    // If initialScroll wouldn't actually scroll anything, trigger instead
    let shouldTriggerOnScroll = true;
    if (initialScrollToRef.current) {
      const { scrollOffsetElement } = osInstance.elements();
      let x = scrollOffsetElement.scrollLeft;
      let y = scrollOffsetElement.scrollLeft;
      if ("x" in initialScrollToRef.current)
        x =
          initialScrollToRef.current.x && initialScrollToRef.current.x > 0
            ? initialScrollToRef.current.x || 0
            : 0;
      if ("y" in initialScrollToRef.current)
        y =
          initialScrollToRef.current.y && initialScrollToRef.current.y > 0
            ? initialScrollToRef.current.y || 0
            : 0;
      if (
        x !== scrollOffsetElement.scrollLeft ||
        y !== scrollOffsetElement.scrollTop
      ) {
        scrollTo(initialScrollToRef.current);
        shouldTriggerOnScroll = false;
      }
      initialScrollToRef.current = null;
    }
    if (shouldTriggerOnScroll) triggerOnScroll(osInstance);
  };
  return (
    <View
      onLayout={handleLayout}
      ref={viewRef}
      style={[
        dynamicStyles.scrollView,
        !scrollEnabled && dynamicStyles.disabled,
        style,
      ]}
      Component={OverlayScrollbarsComponent}
      // ComponentRef={this.scrollbarRef}
      ComponentHtmlRefProp='ref'
      ComponentHtmlRefFunction={(ref) => {
        // const osInstance = osInstanceRef.current;
        // if (!osInstance) return false;
        // const { scrollOffsetElement } = osInstance.elements();
        // htmlRef.current = scrollOffsetElement;
        if (!ref) return null;
        htmlRef.current = ref.getElement();
        return htmlRef;
      }}
      ComponentProps={{
        // ref: (refVal) => console.log("CHECK REF VAL", refVal),
        options: {
          overFlow: overflowMap,
          scrollbars: {
            //theme: "os-theme-dark",
            theme: StyleSheet.className(dynamicStyles.scrollTheme),
            visibility: "auto",
            autoHide: "scroll",
            autoHideDelay: 1300,
            autoHideSuspend: false,
            dragScroll: true,
            clickScroll: false,
            pointers: ["mouse", "touch", "pen"],
          },
        },
        element: "div",
        events: {
          scroll: triggerOnScroll,
          initialized: handleInitializedEvent,
        },
        defer: true,
      }}
      // onScroll={() => console.log("ON SCROLL EVENT FIRED")}
    >
      <View
        style={[dynamicStyles.scrollViewContent, contentStyle]}
        htmlRef={contentHtmlRef}
        onLayout={handleContentSizeChange}
      >
        {children}
      </View>
    </View>
  );
});
